@import 'node_modules/sass-mq/mq';
@import 'node_modules/tachyons-sass/tachyons';

$b: #000;
$w: #fff;

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic,700italic&subset=latin');

body,
.sans-serif {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif;
}

.Navi {
  transition: box-shadow .15s linear;

  &.floating {
    box-shadow: 0 4px 8px rgba($b, .12);
  }

  &__title {
    letter-spacing: 6px;
  }
}

.Featured {
  background-image: url('../img/featured1.jpg');
  background-position: center;

  &--career {
    background-image: url('../img/featured2.jpg');
  }

  &::before {
    background-color: rgba($b, .5);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.Career {

  &__item {
    margin-right: -3px;
  }
}

.Contact {

  &__btn {
    cursor: pointer;
    letter-spacing: 1px;
  }
}

.Map {
  overflow: hidden;

  &__overlay {
    height: 300px;
    margin-top: -300px;
    position: relative;
    top: 300px;
  }
}
